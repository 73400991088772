<!-- home -->
<template>
  <div class="home">
    <div class="dokg">
      <div class="dokg1" @click="jianzhi(2)">找兼职/培训</div>
      <div class="dokg2" @click="jianzhi(1)">我要入驻</div>
    </div>
    <div class="h1 w100">
      <img
        class="himg w100 disblock"
        src="../../assets/img/zxza12.png"
        alt=""
      />
    </div>
    <div class="h2 w100"></div>
    <div class="w100 asdx">
      <div class="aas">
        <div class="a1 b1" :class="index == 0 ? 'bai' : 'hei'" @click="tabs(0)">
          <img
            class="b11"
            v-if="index == 0"
            src="../../assets/img/zxzdx82x.png"
            alt=""
          />
          <div class="z99 cs">01</div>
          <div class="z99 scs">人才输出</div>
        </div>
        <div class="a2 b1" :class="index == 1 ? 'bai' : 'hei'" @click="tabs(1)">
          <img
            class="b11"
            v-if="index == 1"
            src="../../assets/img/zxzdx82x.png"
            alt=""
          />
          <div class="z99 cs">02</div>
          <div class="z99 scs">职前培训</div>
        </div>
        <div class="a3 b1" :class="index == 2 ? 'bai' : 'hei'" @click="tabs(2)">
          <img
            class="b11"
            v-if="index == 2"
            src="../../assets/img/zxzdx82x.png"
            alt=""
          />
          <div class="z99 cs">03</div>
          <div class="z99 scs">校园本地服务</div>
        </div>
      </div>
      <img
        class="himg w100"
        v-show="index == 0"
        src="../../assets/img/zxzdve2.png"
        alt=""
      />
      <img
        class="himg w100"
        v-show="index == 1"
        src="../../assets/img/zxz26xfs.png"
        alt=""
      />
      <img
        class="himg w100"
        v-show="index == 2"
        src="../../assets/img/zxz56+2.png"
        alt=""
      />
    </div>
    <div class="pos">
      <img class="disblock" src="../../assets/img/zxz3vbnm.png" alt="" />
    </div>
    <div style="width: 100%">
      <!-- poster="../../assets/img/3a130.png" -->

      <video
        poster="../../assets/img/videoimgs1.png"
        style="width: 100%"
        src="http://v.zhixiaozi.com/zxz/zxzvideo.mp4"
        controls="controls"
      ></video>
    </div>
    <div style="padding-bottom: 25%">
      <page-footer />
    </div>

    <div class="disflex" v-if="dioindex > -1" @click="jianzhi(-1)">
      <div class="ssp">
        <div v-if="dioindex == 1" class="copyphone" @click.stop="copys">
          复制号码
        </div>
        <img
          v-if="dioindex == 1"
          src="../../assets/img/zxzqss1xcx.png"
          alt=""
        />
        <img
          style="height: auto"
          v-if="dioindex == 2"
          src="../../assets/img/zxzxcxws.png"
          alt=""
        />
        <img
          @click="jianzhi(-1)"
          class="closes"
          src="https://img.zhixiaozi.com/mrcba/images/closell.png"
          alt=""
        />
      </div>
    </div>
    <div class="disflex112" v-show="copys1 == 1">
      <div class="dd2">
        <div class="dd21">已复制</div>
        <div class="dd22" @click.stop="closecopys1">关闭</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuShow: false,
      index: 0,
      dioindex: -1,
      copys1: -1,
    };
  },
  components: {
    "page-footer": () => import("../../components/footer.vue"),
  },
  // mounted() {},
  // destroyed() {
  //   window.removeEventListener("scroll", this.handleScroll, true);
  // },
  // created() {
  //   this.$nextTick(function () {
  //     window.addEventListener("scroll", this.handleScroll, true);
  //   });
  // },
  methods: {
    //监听滚动
    // handleScroll(e) {
    //   console.log("e", document.documentElement.scrollHeight);
    //   let isScroll = document.documentElement.scrollHeight;
    //   this.$refs.heade.changes();
    // },
    copys() {
      //拨打电话
      //window.location.href = "tel://18028289102";
      let url = "18028289102";
      let oInput = document.createElement("input");
      oInput.value = url;
      //oInput.type = "hidden";
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      //console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.remove();
      // this.$dialog.alert({
      //   title: "", //加上标题
      //   message: "已复制", //改变弹出框的内容
      //   //showCancelButton: true, //展示取水按钮
      // });

      this.copys1 = 1;
      this.dioindex = -1;
    },
    closecopys1() {
      this.copys1 = -1;
    },
    jianzhi(i) {
      this.dioindex = i;
    },
    tabs(i) {
      this.index = i;
    },
    changeMenuShow(menuShow) {
      console.log("**");
      this.menuShow = menuShow;
    },
  },

  computed: {},
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
.dd2 {
  background: #ffffff;
  border-radius: 12px;
  position: absolute;
  top: 40%;
  left: 20%;
  width: 60%;
}
.dd21 {
  padding: 1em;
  border-bottom: 1px solid #b6b5b5;
  text-align: center;
}
.dd22 {
  padding: 0.5em;
  text-align: center;
}
.disflex {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
}
.disflex112 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9991;
}
.ssp {
  height: 62%;
  width: 82%;
  position: absolute;
  top: 14%;
  left: 10%;
  text-align: center;
}
.ssp img {
  width: 100%;
  height: 100%;
}
.copyphone {
  background: #ffffff;
  opacity: 1;
  font-size: 1em;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: center;
  color: #08c2cb;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 0.6em 0em;
  border-radius: 0px 0px 10px 10px;
}

.closes {
  width: 30px !important;
  height: 30px !important;
  margin-top: 50px !important;
}
.home {
  height: calc(100% - 65px);
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 20px;
}
.asdx {
  position: relative;
}
.bai .cs {
  margin-top: 26px;
  opacity: 1;
  font-size: 55px;
  font-family: PingFang SC, PingFang SC-Heavy;
  font-weight: 800;
  color: #4ad2d9;
  line-height: 1px;
  letter-spacing: -1px;
}
.bai .scs {
  opacity: 1;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  color: #ffffff;
  line-height: 40px;
  letter-spacing: 3px;
}
.hei .cs {
  margin-top: 26px;
  opacity: 1;
  font-size: 55px;
  font-family: PingFang SC, PingFang SC-Heavy;
  font-weight: 800;
  color: #dce1e3;
  line-height: 1px;
  letter-spacing: -1px;
}
.hei .scs {
  opacity: 1;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
  letter-spacing: 3px;
}
.aas {
  text-align: center;
  position: absolute;
  width: 94%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 107px;
  top: -18px;
  left: 10px;
  background: #ffffff;
}
.dokg {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0px;
  bottom: 0px;
  position: fixed;
  z-index: 999;
  background: #ffffff;
}
.dokg1 {
  height: 44px;
  line-height: 44px;
  text-align: center;
  opacity: 1;
  background: #e1f8f9;
  border: 1px solid #08c2cb;
  border-radius: 10px;
  padding: 1px 25px;
  opacity: 1;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #08c2cb;
}

.dokg2 {
  text-align: center;
  line-height: 44px;
  height: 44px;
  opacity: 1;
  background: #08c2cb;
  padding: 1px 17%;
  border-radius: 10px;

  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: ;
  color: #ffffff;
}
.pos {
  padding: 2% 0%;
  width: 100%;
}
.pos img {
  margin: 0 auto;
  width: 100%;
  height: auto;
  font-size: 80px;
  color: #013644;
}
.b1 {
  width: 126px;
  height: 126px;
}
.b11 {
  width: 33.33%;
  height: 116px;
  position: absolute;
  top: 0px;
}
.z99 {
  z-index: 99;
}
.a1,
.a2,
.a3 {
  height: 126px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: space-evenly;
}
.w100 {
  width: 100%;
}
.disblock {
  display: block;
}
</style>
